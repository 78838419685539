import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button, { ButtonKind } from 'bloko/blocks/button';
import { PenSquareScaleSmall, IconDynamic, IconLink, IconColor } from 'bloko/blocks/icon';

import { changeAddressesList, changeAddressesCount } from 'lux/models/employerAddressesPage';
import { UserType } from 'lux/models/userType';
import AddAddressModal from 'src/components/AddressSuggest/AddAddressModal';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

const EmployerAddressesModal = ({ trls, type, addressId }) => {
    const dispatch = useDispatch();
    const addresses = useSelector(({ employerAddressesPage }) => employerAddressesPage.addresses);
    const employerManagerId = useSelector(({ employerManager }) => employerManager?.id);
    const employerManagersList = useSelector(({ employerManagersList }) => employerManagersList);
    const employerAddressesCount = useSelector(({ employerAddressesPage: { addressesCount } }) => addressesCount);
    const isBackoffice = useSelector(({ userType }) => userType === UserType.BackOffice);
    const employerManagerName = isBackoffice
        ? employerManagersList.find((manager) => manager.isMCP)?.text
        : employerManagersList.find((manager) => manager.id === employerManagerId)?.text;
    const currentAddress = addresses.find((address) => address.id === addressId);
    const [isModalVisible, toggleIsModalVisible] = useToggleState(false);

    return (
        <>
            {type === 'edit' ? (
                <IconDynamic>
                    <IconLink onClick={toggleIsModalVisible} data-qa="address-item-edit" data-address-id={addressId}>
                        <PenSquareScaleSmall initial={IconColor.Gray50} highlighted={IconColor.Gray80} />
                    </IconLink>
                </IconDynamic>
            ) : (
                <Button kind={ButtonKind.Primary} onClick={toggleIsModalVisible} data-qa="address-add-link">
                    {trls[EmployerAddressesModal.trls.addAddress]}
                </Button>
            )}
            <AddAddressModal
                onCreate={(rawAddress) => {
                    rawAddress.manager = { fullname: employerManagerName };
                    rawAddress.isNewAddress = true;
                    const newAddresses = [rawAddress, ...addresses];
                    dispatch(changeAddressesList(newAddresses));
                    dispatch(changeAddressesCount(employerAddressesCount + 1));
                }}
                onSelect={() => {}}
                onUpdate={(rawAddress) => {
                    rawAddress.manager = {
                        fullname: type === 'edit' ? currentAddress.manager.fullname : employerManagerName,
                    };
                    rawAddress.canEdit = true;
                    const newAddresses = addresses.map((address) =>
                        address.id === rawAddress.id ? rawAddress : address
                    );
                    dispatch(changeAddressesList(newAddresses));
                }}
                onDeleteClicked={(id) => {
                    toggleIsModalVisible();
                    window.dispatchEvent(new CustomEvent('HH-AddressEdit-Remove-Clicked', { detail: id }));
                }}
                visible={isModalVisible}
                onClose={toggleIsModalVisible}
                addressId={addressId}
                duplicate="show"
            />
        </>
    );
};

EmployerAddressesModal.propTypes = {
    trls: PropTypes.object,
    type: PropTypes.string.isRequired,
    addressId: PropTypes.number,
};

EmployerAddressesModal.trls = {
    addAddress: 'employer.address.add_a',
};

export default translation(EmployerAddressesModal);
